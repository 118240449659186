.home-wrapper {
  width: 100%;
  overflow: hidden;
}

.home-wrapper-inner {
  max-width: 100%;
  padding: 20px;
  overflow-x: hidden;
}

.home-options {
  width: 100%;
}

.home-deals-table {
  padding: 20px;
  overflow: hidden;
}

.pagination-div{
  float: right;
}