.layout-wrapper {
  max-width: 100%;
  min-height: 100%;
  position: relative;
}

.layout-header {
  width: 100%;
  overflow: hidden;
  background-color: #FFEFD5;
  position: fixed;
  top: 0px;
  z-index: 5;
  height: 80px;
}

.layout-header-inner {
  max-width: 100%;
  padding: 20px;
  overflow: hidden;
}

.menu-container {
  position: relative;
  float: right;
}

.account_menu {
  margin-top: 40px;
}

.layout-content {
  position: relative;
  min-height: 100vh;
  top: 80px;
  margin: auto;
  margin-bottom: 100px;
  display: block;
  overflow: hidden;
}

.layout-content-inner {
  max-width: 1170px;
  margin: auto;
  position: relative;
}

.clearfooter {
  height: 100px;
  clear: both;
}

.layout-footer {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
  background-color: #0d3c55;
  display: block;
  margin: auto;
}

.layout-footer-inner {
  padding: 20px;
  width: 100%;
  color: white;
  background-color: #0d3c55;
}