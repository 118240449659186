.form-builder-wrapper {
  width: 100%;
  overflow: hidden;
}

.form-builder-inner {
  max-width: 100%;
  padding: 20px;
  overflow-x: hidden;
}

.form-builder-canvas {
  padding: 20px;
  overflow: hidden;
}

.form-builder-canvas-components {
  max-width: 400px;
  float: left;
  position: relative;
  clear: both;
  padding: 20px;
}

.form-builder-canvas-component {
  padding: 20px;
  margin-top: 20px;
  cursor: move;
}

.form-builder-canvas-drop-area {
  border: dotted;
  border-width: 1px;
  overflow: hidden;
  position: relative;
  min-height: 200px;
  padding: 20px;
}

.empty-drag-area {
  text-align: center;
}

.empty-drag-area h3 {
  color: grey;
}

.embed-code-div-outer {
  overflow: hidden;
}

.embed-code-div {
  background-color: grey;
  color: black;
  overflow: hidden;
  padding: 20px;
  text-align: left;
}